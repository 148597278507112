<template>
	<div>
		<top-nav backImage bgColor="#fff" @back="back"><span class="title">费率明细</span></top-nav>
		<img :src="product.imgSrc" alt="" />
		<div class="content" v-html="product.hint">
		</div>

	</div>
</template>

<script>
export default {
	data() {
		return {
			product:{}
		};
	},
	created () {
		this.product=this.$route.params.detail
		
	},
	methods: {
		back() {
			this.$router.go(-1);
		},
	},
};
</script>

<style lang="scss" scoped>
.title {
	color: #000 !important;
}
img {
	width: 100%;
}
.content{
font-size: 0.2rem;
color: rgba(0,0,0,0.6);
width: 100%;
padding:0.2rem 0.4rem ;
box-sizing: border-box;
overflow: hidden;
}
</style>
